import React, { Fragment, useState } from 'react';
import styled from '@emotion/styled';
import { readableColor, darken, lighten } from 'polished';

import { Global } from '@emotion/react';
import { Flex, IconButton } from 'theme-ui';
import Text from '../../components/Text';
import ColorPicker from '../../components/ColorPicker';
import InfoModal from '../../components/InfoModal';
import CreateEntry from './CreateEntry';
import Header from '../../components/Header';
import { nanoid } from 'nanoid';

const Editor = () => {
  const [editorColor, setEditorColor] = useState('#202020');
  const [lines, setLines] = useState([
    { id: nanoid(), text: '', placeholder: 'Your', color: '' },
    { id: nanoid(), text: '', placeholder: 'Text', color: '' },
  ]);

  const updateText = (position: number) => {
    return (text: string) => {
      const newLines = [...lines];
      newLines[position].text = text;
      setLines(newLines);
    };
  };

  const updateColor = (position: number) => {
    return (color: string) => {
      const newLines = [...lines];
      newLines[position].color = color;
      setLines(newLines);
    };
  };

  const removeRow = (position: number) => {
    return () => {
      const newLines = [...lines];
      newLines.splice(position, 1);
      setLines([...newLines]);
    };
  };

  const addRow = () => {
    const newLines = [...lines, { id: nanoid(), text: '', placeholder: 'Text', color: '' }];
    setLines(newLines);
  };
  // if (!firebase.auth) {
  //   return null;
  // }
  return (
    <Fragment>
      <Global
        styles={{
          body: {
            transition: 'background-color ease-in-out 0.3s',
            backgroundColor: editorColor,
            minWidth: 'fit-content',
          },
        }}
      />
      <Header />
      <StyledFlex>
        <Toolbar m="0 0 0 8px">
          <ColorPicker
            color={editorColor}
            onChange={setEditorColor}
            title="Choose Background Color"
            editorColor={editorColor}
          />
          <InfoModal color={editorColor} />
        </Toolbar>
        <Flex sx={{ minWidth: '80vw', flexDirection: 'column' }}>
          {lines.map((l, k) => (
            <Text
              key={l.id}
              id={l.id}
              placeholder={l.placeholder}
              position={k}
              editorColor={editorColor}
              fontColor={l.color.length > 0 ? l.color : readableColor(editorColor)}
              last={k === lines.length - 1}
              onChange={updateText(k)}
              value={l.text}
              onChangeFontColor={updateColor(k)}
              onRemoveLine={removeRow(k)}
              canDelete={lines.length > 2}
            />
          ))}
        </Flex>
        <Toolbar mt="30px">
          <IconButton
            onClick={() => addRow()}
            padding="0"
            title="Add Row"
            sx={{
              cursor: 'pointer',
              width: '32px',
              height: '32px',
              alignItems: 'center',
              justifyContent: 'center',
              alignContent: 'center',
              marginLeft: '4px',
              stroke: `${readableColor(editorColor, darken(0.1, editorColor), lighten(0.2, editorColor), false)}`,
              fill: `${readableColor(editorColor, darken(0.1, editorColor), lighten(0.2, editorColor), false)}`,
            }}
          >
            <svg viewBox="0 0 349.03 349.03" height="100%" width="100%">
              <defs />
              <path d="M349.03 141.23v66.58a9.08 9.08 0 01-9.08 9.07H216.88v123.07a9.08 9.08 0 01-9.08 9.08h-66.57c-5.01 0-9.08-4.06-9.08-9.08V216.88H9.08A9.08 9.08 0 010 207.8v-66.57a9.08 9.08 0 019.08-9.08h123.07V9.08A9.08 9.08 0 01141.23 0h66.58a9.08 9.08 0 019.07 9.08v123.07h123.07a9.08 9.08 0 019.08 9.08z" />
            </svg>
          </IconButton>
          <CreateEntry entry={{ editorColor, lines }} />
        </Toolbar>
      </StyledFlex>
    </Fragment>
  );
};

const StyledFlex = styled(Flex)`
  min-width: 80vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
`;

const Toolbar = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 80vw;
`;

export default Editor;
