import React, { Fragment, useState } from 'react';
import { SketchPicker } from 'react-color';
import { Card } from 'theme-ui';
import styled from '@emotion/styled';
import { readableColor, darken, lighten } from 'polished';

type ColorPickerProps = {
  color: string;
  onChange: any;
  title?: string;
  editorColor: string;
  id?: string;
};

const ColorPicker = ({ color, onChange, title = 'Choose Color', editorColor, id }: ColorPickerProps) => {
  const [isShown, setShown] = useState(false);
  const [c, setColor] = useState(color);

  return (
    <Fragment>
      {isShown && (
        <Cover
          onClick={() => {
            setShown(false);
          }}
        />
      )}
      <StyledCard title={title} color={c} editorColor={editorColor} onClick={() => setShown(true)}>
        <div className="color" />
        {isShown && (
          <Fragment>
            <Picker
              color={c}
              key={`${id}-color-${c}`}
              onChange={(c) => setColor(c.hex)}
              onChangeComplete={(c) => {
                onChange(c.hex);
              }}
              disableAlpha={true}
            />
          </Fragment>
        )}
      </StyledCard>
    </Fragment>
  );
};

export default ColorPicker;

const StyledCard = styled(Card)<any>`
  padding: 4px;
  cursor: pointer;
  background-color: ${(p) =>
    readableColor(p.editorColor, darken(0.1, p.editorColor), lighten(0.2, p.editorColor), false)};
  border-radius: 2px;
  height: 29px;
  & .color {
    width: 25px;
    height: 20px;
    background-color: ${(p) => p.color};
  }
  line-height: normal;
  box-shadow: 3px 3px 6px ${(p) => darken(0.04, p.editorColor)}, -3px -3px 6px ${(p) => lighten(0.03, p.editorColor)};
  transition: box-shadow ease-in-out 0.4s;
`;

const Cover = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  z-index: 1;
`;

const Picker = styled(SketchPicker)`
  margin-top: 8px;
  margin-left: -20px;
  position: absolute;
  z-index: 2;
`;
