import React, { Fragment, useEffect, useRef, useState } from 'react';
import { ref, uploadBytesResumable, getStorage } from 'firebase/storage';
import { Global } from '@emotion/react';
import { RouteComponentProps } from '@reach/router';
import { doc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocData, useStorage, useStorageDownloadURL } from 'reactfire';
import { readableColor } from 'polished';
import { Button } from 'theme-ui';
import { renderToStaticMarkup } from 'react-dom/server';

const fontSize = 200;
const spacing = 43;
export interface ViewProps extends RouteComponentProps {
  id: string;
}
const View = ({ id }: ViewProps) => {
  const [imageUrl, setImageUrl] = useState('');
  const [blob, setBlob] = useState('');
  const firestore = useFirestore();
  const storage = useStorage();
  const docRef = doc(firestore, 'entries', id);
  const { status, data: entry } = useFirestoreDocData(docRef);
  const { status: fileStatus, data: imageURL } = useStorageDownloadURL(ref(storage, `entries/${id}`));

  if (!entry) {
    return null;
  }
  if (fileStatus === 'loading') {
    return <div>Loading...</div>;
  }

  return <img style={{ maxWidth: '100%' }} src={imageURL} />;
  // const { editorColor, words } = entry;
  // const handleExport = () => {
  //   const uri = stageRef.current.toDataURL();
  //   downloadURI(uri, `${linesToString(words, '-')}.png`);
  // };
  // const altColor = readableColor(editorColor);
  // return (
  //   <Fragment>
  //     <Global
  //       styles={{
  //         body: {
  //           backgroundColor: editorColor,
  //         },
  //       }}
  //     />
  //     {isFont && (
  //       <Stage width={1000} height={800} ref={stageRef}>
  //         <Layer>
  //           <Rect fill={editorColor} width={window.innerWidth} height={800} />
  //           {words.map((w: any, i: number) => {
  //             const end = i >= words.length - 1 ? '.' : '&';
  //             return (
  //               <Text
  //                 key={`word-${i}`}
  //                 fontSize={fontSize}
  //                 y={fontSize * i - spacing * i}
  //                 fontFamily="tex_gyre_herosbold"
  //                 text={`${w.text}${end}`}
  //                 fill={w.color || altColor}
  //               />
  //             );
  //           })}
  //         </Layer>
  //       </Stage>
  //     )}
  //     <Button onClick={handleExport}>Download</Button>
  //     {/* {isFont && <SVGRep words={words} altColor={altColor} editorColor={editorColor} />} */}
  //     {isFont && <SVGToImg words={words} altColor={altColor} editorColor={editorColor} />}
};

const SVGRep = ({ words, altColor, editorColor }: any) => {
  const height = words.length * 20 + 7;
  return (
    <svg
      style={{ border: '1px solid white' }}
      xmlns="http://www.w3.org/2000/svg"
      width={800}
      viewBox={`0 0 100 ${height}`}
    >
      <rect x="0" y="0" fill={editorColor} width={100} height={height} />
      {words.map((w: any, i: number) => {
        const end = i >= words.length - 1 ? '.' : '&';
        return (
          <text
            key={`word-${i}`}
            style={{
              fontSize: '26',
              fontFamily: 'tex_gyre_herosbold',
              fill: w.color || altColor,
            }}
            y={20 * (i + 1)}
          >{`${w.text}${end}`}</text>
        );
      })}
    </svg>
  );
};

const SVGToImg = ({ words, altColor, editorColor }: any) => {
  const svgString = encodeURIComponent(
    renderToStaticMarkup(<SVGRep words={words} altColor={altColor} editorColor={editorColor} />)
  );
  const dataUri = `data:image/svg+xml,${svgString}`;
  return <img style={{ border: '1px solid white' }} alt="thing" src={dataUri} />;
};

function downloadURI(uri: string, name: string) {
  var link = document.createElement('a');
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export default View;
