import React, { useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { readableColor, darken, lighten } from 'polished';

import { Flex, Input, Box, IconButton } from 'theme-ui';
import ColorPicker from './ColorPicker';

const minSize = 5;
const maxSize = 50;

type TextProps = {
  placeholder: string;
  last?: boolean;
  editorColor: string;
  position: number;
  onChange: Function;
  onChangeFontColor: Function;
  onRemoveLine: Function;
  value: string;
  fontColor: string;
  canDelete: boolean;
  id: string;
};

const Text = ({
  placeholder,
  last = false,
  editorColor,
  position,
  onChange,
  value,
  fontColor,
  onChangeFontColor,
  onRemoveLine,
  canDelete,
  id,
}: TextProps) => {
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    ref.current?.focus();
  }, []);
  const end = last ? '.' : '&';
  const handleKeyDown = (e: any) => {
    if (e.key === '&' || e.key === '.') {
      e.preventDefault();
      return false;
    }
  };
  return (
    <Flex
      marginBottom="-1vh"
      sx={{
        minHeight: `calc(${minSize + 1.5}rem + (${maxSize} - ${minSize}) * ((100vw - 400px) / (1200 - 400)));`,
        position: 'relative',
        alignContent: 'center',
        margin: '0 auto',
        minWidth: '100%',
        flexDirection: 'row',
        fontFamily: 'wamps',
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          position: 'relative',
          minWidth: '40px',
          marginRight: '12px',
        }}
      >
        {canDelete && (
          <IconButton
            title="Remove Row"
            onClick={() => onRemoveLine()}
            sx={{
              cursor: 'pointer',
              width: '48px',
              height: '48px',
              alignItems: 'center',
              justifyContent: 'center',
              alignContent: 'center',
              marginBottom: '12px',
              stroke: `${readableColor(editorColor, darken(0.1, editorColor), lighten(0.2, editorColor), false)}`,
              fill: `${readableColor(editorColor, darken(0.1, editorColor), lighten(0.2, editorColor), false)}`,
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            </svg>
          </IconButton>
        )}
        <ColorPicker
          key={`text-${id}-fontColor-${fontColor}`}
          color={fontColor}
          onChange={onChangeFontColor}
          title="Change Font Color"
          editorColor={editorColor}
        />
        <Box></Box>
      </Flex>
      <Flex sx={{ position: 'relative', width: '100%' }}>
        <StyledInput
          sx={{
            outline: 'none',
            border: 'none',
            borderRadius: 0,
            left: '0',
            borderBottomStyle: 'solid',
            borderBottomWidth: '2px',
          }}
          editorColor={editorColor}
          onKeyDown={handleKeyDown}
          onChange={(e: any) => onChange(e.target.value.trim())}
          defaultValue={value}
          ref={ref}
          fontColor={fontColor ? fontColor : readableColor(editorColor)}
        />
        <EndDiv
          showEnder={value.length > 0 ? true : false}
          editorColor={editorColor}
          fontColor={fontColor ? fontColor : readableColor(editorColor)}
        >
          <div className="hidden">{value.length ? value : placeholder}</div>
          <div className="ender">{end}</div>
        </EndDiv>
      </Flex>
    </Flex>
  );
};

const EndDiv = styled.div<any>`
  position: relative;
  white-space: nowrap;
  left: 0;
  border-bottom: 2px solid transparent;
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: calc(${minSize}rem + (${maxSize} - ${minSize}) * ((100vw - 400px) / (1200 - 400)));
  padding: 0;
  margin: 0;
  line-height: normal;
  pointer-events: none;
  & .hidden {
    color: ${(p) =>
      p.showEnder
        ? 'transparent'
        : readableColor(p.editorColor, darken(0.1, p.editorColor), lighten(0.2, p.editorColor), false)};
    transition: color ease-in-out 0.3s;
  }
  & .ender {
    color: ${(p) => p.fontColor};
    transition: color ease-in-out 0.3s;
  }
`;

const StyledInput = styled(Input)<any>`
  position: absolute;
  font-size: calc(${minSize}rem + (${maxSize} - ${minSize}) * ((100vw - 400px) / (1200 - 400)));
  font-family: 'tex_gyre_herosbold';
  padding: 0;
  margin: 0;
  line-height: normal;
  transition: color ease-in-out 0.4s;
  left: 0;
  right: 0;
  color: ${(p) => p.fontColor};
  border-bottom-color: ${(p) =>
    readableColor(p.editorColor, darken(0.1, p.editorColor), lighten(0.2, p.editorColor), false)};
`;
export default Text;
