import React, { Fragment, useEffect, useState } from 'react';
import { doc, collection, orderBy, query, getDocs, QuerySnapshot } from 'firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import { readableColor, darken, parseToRgb, rgbToColorString } from 'polished';
import { Card, Text, Flex, Box } from 'theme-ui';
import { Link } from '@reach/router';
import { linesToString } from '../../utils';
import Header from '../../components/Header';
import { faComment } from '@fortawesome/free-regular-svg-icons';
import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons';
import { PostIcon, Count, LikeButton, FlagButton } from '../../components/Buttons';

//create a function to loop through all entries in the entry collection and add the likes field
// const addLikes = async (entriesCollection: any, entries: any[]) => {
//   entries.forEach(async (entry: any) => {
//     const entryRef = doc(entriesCollection, entry.id);
//     await updateDoc(entryRef, { likes: 0, commentsCount: 0, comments: [], flags: 0 });
//   });
// };

const Index = () => {
  const [entries, setEntries] = useState<QuerySnapshot | undefined>(undefined);
  const firestore = useFirestore();
  useEffect(() => {
    const getEntries = async () => {
      if (!entries) {
        const entriesCollection = collection(firestore, 'entries');
        const entriesQuery = query(entriesCollection, orderBy('likes', 'desc'), orderBy('created', 'desc'));
        const results = await getDocs(entriesQuery);
        setEntries(results);
      }
    };
    getEntries();
  }, []);

  if (!entries) {
    return <div> Loading... </div>;
  }
  // addLikes(entriesCollection, entries);
  return (
    <Fragment>
      <Header />
      <Flex
        sx={{
          margin: '0 auto',
          marginTop: '82px',
          width: '100vw',
          maxWidth: '600px',
          alignItems: 'center',
          flexWrap: 'wrap',
          justifySelf: 'center',
          justifyItems: 'center',
          justifyContent: 'center',
        }}
      >
        {entries.docs.map((entry) => {
          return <EntryCard id={entry.id} />;
        })}
      </Flex>
    </Fragment>
  );
};

export default Index;

const EntryCard = ({ id }: any) => {
  const firestore = useFirestore();

  const entryRef = doc(firestore, 'entries', id);
  const { status, data: entry } = useFirestoreDocData(entryRef);
  if (status === 'loading' || !entry || !entry.words) {
    return null;
  }
  const wLength = Object.keys(entry.words).length;
  const altFontColor = readableColor(entry.editorColor);
  return (
    <Card
      sx={{
        borderRadius: '4px',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: `${entry.editorColor || '#0000'}`,
        boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.3)',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        margin: '8px',
        maxWidth: '600px',
        justifyContent: 'center',
        justifyItems: 'flex-start',
      }}
    >
      <Link style={{ textDecoration: 'none' }} to={`/e/${linesToString(entry.words)}/${id}`}>
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '400px',
            maxWidth: '600px',
            minWidth: '500px',
            width: '100%',
            margin: '0',
            padding: '0 8px',
            justifyContent: 'center',
            justifyItems: 'flex-start',
            borderBottom: `1px solid ${
              entry.editorColor && rgbToColorString(parseToRgb(entry.editorColor)) === '#fff'
                ? darken(0.1, entry.editorColor)
                : '#0000'
            }`,
          }}
          backgroundColor={entry.editorColor || 'black'}
        >
          {Object.keys(entry.words).map((key, i) => {
            const w = entry.words[key];
            const end = i >= wLength - 1 ? '.' : '&';
            return (
              <Card
                sx={{
                  borderRadius: '4px',
                }}
              >
                <Text
                  key={key}
                  sx={{
                    lineHeight: '.85',
                    whiteSpace: 'nowrap',
                    fontSize: '56px',
                    fontFamily: 'tex_gyre_herosbold',
                  }}
                  color={w.color || altFontColor}
                >
                  {w.text.trim()}
                  {end}
                </Text>
              </Card>
            );
          })}
        </Card>
      </Link>
      <Fragment>
        <Flex sx={{ justifyContent: 'space-between', alignItems: 'center', margin: '0 8px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyItems: 'center', padding: '8px 0' }}>
            <LikeButton entry={{ id, ...entry }} color={entry.editorColor} />
            <PostIcon color={entry.editorColor} icon={faComment} title="Comment" />
            <Count color={entry.editorColor}>0</Count>
            <FlagButton entry={{ id, ...entry }} color={entry.editorColor} />
          </Box>
          <PostIcon color={entry.editorColor} icon={faShoppingBasket} title="Shop this design" />
        </Flex>
      </Fragment>
    </Card>
  );
};
