import React, { Fragment, useRef, useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { readableColor } from 'polished';
import { Stage, Layer, Text, Rect } from 'react-konva';
import useFontFaceObserver from 'use-font-face-observer';

const fontSize = 200;
const spacing = 43;
export interface ViewProps extends RouteComponentProps {
  entry: any;
  setDataUrl: Function;
}
const ImgCreator = React.forwardRef(({ entry, setDataUrl }: ViewProps, ref: any) => {
  const [textHeight, setTextHeight] = useState(0);
  const [stageWidth, setStageWidth] = useState(0);
  const isFont = useFontFaceObserver([{ family: 'tex_gyre_herosbold' }]);

  const layerRef = useRef<any>(null);
  useEffect(() => {
    if (!ref || !ref.current) {
      return;
    }
    var lines = layerRef.current.getChildren((node: { getClassName: () => string }) => {
      return node.getClassName() === 'Text';
    });
    let max = 0;
    let maxH = 0;
    lines.forEach((l: { getWidth: () => number; getHeight: () => number }, i: number) => {
      const w = Math.round(l.getWidth());
      const h = Math.round(l.getHeight());
      if (w > max) {
        max = w + 14;
      }
      if (h > maxH) {
        maxH = h;
      }
    });

    if (max !== stageWidth) {
      setStageWidth(max);
    }
    if (maxH !== textHeight) {
      setTextHeight(maxH);
    }
    ref.current.draw();
    // setDataUrl(stageRef.current.toDataURL({ pixelRatio: 4 }));
  }, [setDataUrl, entry, stageWidth, textHeight]);

  if (!entry) {
    return null;
  }
  const { editorColor, lines = [] } = entry;

  const altColor = readableColor(editorColor);
  const height = textHeight * lines.length - lines.length * 10;
  return (
    <Fragment>
      {isFont && (
        <Stage width={stageWidth} height={height} ref={ref} style={{ display: 'none' }}>
          <Layer ref={layerRef}>
            <Rect fill={editorColor} width={stageWidth} height={height} />
            {lines.map((w: any, i: number) => {
              const end = i >= lines.length - 1 ? '.' : '&';
              return <Line index={i} text={`${w.text}${end}`} color={w.color || altColor} />;
            })}
          </Layer>
        </Stage>
      )}
    </Fragment>
  );
});

const Line = ({ color, index, text }: any) => {
  const ref = useRef<any>(null);

  return (
    <Text
      ref={ref}
      key={`word-${index}`}
      x={8}
      fontSize={fontSize}
      y={fontSize * index - spacing * index}
      fontFamily="tex_gyre_herosbold"
      text={text}
      fill={color}
    />
  );
};

export default ImgCreator;
