import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import * as serviceWorker from './serviceWorker';
import { FirebaseAppProvider } from 'reactfire';
import { useInitFirestore, FirestoreProvider } from 'reactfire';

const firebaseConfig = {
  apiKey: 'AIzaSyCMGd91Y7MMhWPzclngsfz-P_EkQxiSVXE',
  authDomain: 'words-and-ampersands.firebaseapp.com',
  projectId: 'words-and-ampersands',
  storageBucket: 'words-and-ampersands.appspot.com',
  messagingSenderId: '484710130993',
  appId: '1:484710130993:web:3bb5accca2e7792ad6bc10',
  measurementId: 'G-TTFSB6S0PE',
};

ReactDOM.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <App />
  </FirebaseAppProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
