import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex, Box, Link } from 'theme-ui';
import styled from '@emotion/styled';

const Header = () => (
  <Flex
    sx={{
      lineHeight: '.85',
      backgroundColor: '#202020',
      position: 'fixed',
      alignItems: 'center',
      justifyContent: 'space-between',
      top: '0px',
      height: '60px',
      width: '100%',
      borderBottom: '1px solid #000',
    }}
  >
    <Box sx={{ fontFamily: 'wamps', fontSize: '24px', justifySelf: 'center', margin: 'auto' }}>
      <HeaderLink href="/">
        Words&
        <br /> Amps.
      </HeaderLink>
    </Box>
    <Box sx={{ alignSelf: 'center', justifySelf: 'center', marginRight: '8px' }}>
      <Box sx={{ alignSelf: 'center', justifySelf: 'center' }}>
        <Link title="Create your own" href="/create">
          <PlusIcon icon={faPlus} />
        </Link>
      </Box>
    </Box>
  </Flex>
);
const HeaderLink = styled(Link)`
  color: #fff;
  &:hover {
    color: #fff;
  }
`;

const PlusIcon = styled(FontAwesomeIcon)`
  color: #fff;
  font-size: 36px;
`;

export default Header;
