import React, { Fragment, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Card, Text, Flex } from 'theme-ui';
import { useFirestore } from 'reactfire';
import { collection, doc, increment, setDoc, updateDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { darken, lighten, readableColor } from 'polished';
import { faHeart, faComment, faFlag } from '@fortawesome/free-regular-svg-icons';

export const LikeButton = ({ entry, color }: any) => {
  const firestore = useFirestore();
  const { id, likes } = entry;

  const AddLike = async () => {
    const entryRef = doc(firestore, 'entries', id);
    if (likes) {
      return await updateDoc(entryRef, { likes: increment(1) });
    }
    return await setDoc(entryRef, { likes: 1 }, { merge: true });
  };
  return (
    <Fragment>
      <PostIcon
        onClick={(event: any) => {
          event.preventDefault();
          AddLike();
        }}
        color={color}
        icon={faHeart}
        title="Like"
      />
      <Count color={color}>{likes || 0}</Count>
    </Fragment>
  );
};

export const FlagButton = ({ entry, color }: any) => {
  const firestore = useFirestore();
  const { id, flags } = entry;

  const AddFlag = async () => {
    const entryRef = doc(firestore, 'entries', id);
    if (flags) {
      return await updateDoc(entryRef, { flags: increment(1) });
    }
    return await setDoc(entryRef, { flags: 1 }, { merge: true });
  };
  return (
    <Fragment>
      <PostIcon
        onClick={(event: any) => {
          event.preventDefault();
          AddFlag();
        }}
        color={color}
        icon={faFlag}
        title="Flag item as inappropriate"
      />
    </Fragment>
  );
};

export const PostIcon = styled(FontAwesomeIcon)<any>`
  font-size: 22px;
  padding: 0 8px;
  color: ${(props) => readableColor(props.color, darken(0.15, props.color), lighten(0.1, props.color), false)};
  &:hover {
    color: ${(props) => readableColor(props.color, darken(0.25, props.color), lighten(0.3, props.color), false)};
  }
  cursor: pointer;
`;

export const Count = styled(Text)<any>`
  padding: 0 8px 0 0;
  color: ${(props) => readableColor(props.color, darken(0.15, props.color), lighten(0.1, props.color), false)};
`;
