import React, { Fragment, useState, useCallback } from 'react';
import { Box, Flex, Close } from 'theme-ui';
import styled from '@emotion/styled';
import { readableColor, darken, lighten } from 'polished';

type InfoModalProps = {
  color: string;
  title?: string;
};
const InfoModal = ({ color = '#1a1919', title }: InfoModalProps) => {
  const [isShown, setShown] = useState(false);
  color = color === '#000' ? '#1a1919' : color;
  const onClose = (e: any) => {
    e.stopPropagation();
    setShown(false);
  };

  return (
    <Fragment>
      {isShown && (
        <Cover
          onClick={() => {
            setShown(false);
          }}
        />
      )}
      <InfoIcon title={title} color={color} onClick={() => setShown(true)}>
        i
        {isShown && (
          <Modal color={color}>
            <ModalClose color={color} onClick={onClose} />
            Create your own ampersand text graphic. You can change the color of each line of text and the background
            color. You can also add or delete lines of text. Once finished just click create to generate an image you
            can download.
          </Modal>
        )}
      </InfoIcon>
    </Fragment>
  );
};

const InfoIcon = styled(Box)<any>`
  position: relative;
  height: 30px;
  width: 30px;
  line-height: normal;
  cursor: pointer;
  border-radius: 50%;
  align-content: center;
  justify-content: center;
  border-style: solid;
  border-width: 3px;
  border-color: ${(p) => readableColor(p.color, darken(0.1, p.color), lighten(0.2, p.color), false)};
  color: ${(p) => readableColor(p.color, darken(0.1, p.color), lighten(0.2, p.color), false)};
  text-align: center;
  font-size: 16px;
  box-shadow: 3px 3px 6px ${(p) => darken(0.06, p.color)}, -3px -3px 6px ${(p) => lighten(0.03, p.color)};
`;

const Cover = styled(Flex)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
`;

const Modal = styled(Flex)<any>`
  border: 2px solid ${(p) => readableColor(p.color, darken(0.1, p.color), lighten(0.2, p.color), false)};
  cursor: default;
  text-align: justify;
  align-self: center;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  border-radius: 16px;
  background: linear-gradient(145deg, ${(p) => lighten(0.02, p.color)}, ${(p) => darken(0.02, p.color)});
  width: 350px;
  background-color: ${(p) => p.theme.colors.primary};
  color: ${(p) => readableColor(p.color, darken(0.25, p.color), lighten(0.4, p.color))};
  z-index: 2;
  margin: auto;
  padding: 8px 20px 20px 20px;
  right: -3px;
  top: -3px;
  box-shadow: 18px 18px 36px ${(p) => darken(0.04, p.color)}, -18px -18px 33px ${(p) => lighten(0.02, p.color)};
`;
const ModalClose = styled(Close)<any>`
  color: ${(p) => p.theme.colors.modalText};
  align-self: flex-end;
  cursor: pointer;
  padding: 0;
  color: ${(p) => readableColor(p.color, darken(0.3, p.color), lighten(0.4, p.color))};
`;

export default InfoModal;
