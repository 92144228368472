import React from 'react';
import { ThemeProvider } from 'theme-ui';
import { initializeFirestore, enableIndexedDbPersistence } from 'firebase/firestore';
import { useInitFirestore, FirestoreProvider, StorageProvider, useFirebaseApp, AuthProvider } from 'reactfire';
import { getAuth, signInAnonymously } from 'firebase/auth';

import theme from './theme/default';
import { Router, RouteComponentProps } from '@reach/router';
import Editor from './features/edit/Editor';
import View from './features/View';
import Index from './features/index/Index';
import { getStorage } from 'firebase/storage';

let CreateRoute = (props: RouteComponentProps) => <Editor />;
let IndexRoute = (props: RouteComponentProps) => <Index />;
let ViewRoute = (props: any) => <View {...props} />;
// let ViewRoute = (props: RouteComponentProps) => <View />;

function App() {
  const app = useFirebaseApp();
  const auth = getAuth(app);
  signInAnonymously(auth);
  const { status, data: firestoreInstance } = useInitFirestore(async (firebaseApp) => {
    const db = initializeFirestore(firebaseApp, {});
    // tslint:disable-next-line
    await enableIndexedDbPersistence(db, {});
    return db;
  });
  if (status === 'loading') {
    return <div>Loading...</div>;
  }
  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <AuthProvider sdk={auth}>
        <StorageProvider sdk={getStorage(app)}>
          <React.StrictMode>
            <ThemeProvider theme={theme}>
              <Router>
                <CreateRoute path="/create" />
                <ViewRoute path="/e/:extra/:id/" />
                <IndexRoute default />
              </Router>
            </ThemeProvider>
          </React.StrictMode>
        </StorageProvider>
      </AuthProvider>
    </FirestoreProvider>
  );
}

export default App;
