import React, { Fragment, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useNavigate } from '@reach/router';
import { readableColor, darken, lighten } from 'polished';
import { Button } from 'theme-ui';
import { linesToString } from '../../utils';
import ImgCreator from './ImgCreator';
import { useFirestore } from 'reactfire';
import { collection, doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadString } from 'firebase/storage';

const CreateEntry = ({ entry }: any) => {
  const [dataUrl, setDataUrl] = useState('');
  const stageRef = useRef<any>(null);
  const navigate = useNavigate();
  const firestore = useFirestore();
  const { editorColor, lines } = entry;
  const isEmpty = lines.filter((l: any) => l.text === '');
  const AddNewEntry = async () => {
    const words = lines.map((l: any) => ({ color: l.color, text: l.text }));
    const entriesCollection = collection(firestore, 'entries');
    const newEntryRef = doc(entriesCollection);
    await setDoc(newEntryRef, {
      editorColor,
      words,
      likes: 0,
      commentsCount: 0,
      comments: [],
      flags: 0,
      created: serverTimestamp(),
    });

    const storage = getStorage();
    const storageRef = ref(storage, `entries/${newEntryRef.id}`);

    const data = stageRef.current.toDataURL({ pixelRatio: 4 });
    uploadString(storageRef, data, 'data_url').then((snapshot) => {
      const text = linesToString(lines);
      navigate(`/e/${text}/${newEntryRef.id}`, { replace: false });
    });
  };
  return (
    <Fragment>
      <StyledButton
        disabled={isEmpty.length > 0}
        fontColor={readableColor(editorColor)}
        editorColor={editorColor}
        onClick={(event: any) => {
          event.preventDefault();
          AddNewEntry();
        }}
      >
        Create
      </StyledButton>
      <ImgCreator entry={{ editorColor, lines }} setDataUrl={setDataUrl} ref={stageRef} />
    </Fragment>
  );
};
export default CreateEntry;

const StyledButton = styled(Button)<any>`
  cursor: pointer;
  text-transform: uppercase;
  background-color: ${(p) => p.editorColor};
  border: 2px solid
    ${(p) => readableColor(p.editorColor, darken(0.1, p.editorColor), lighten(0.2, p.editorColor), false)};
  border-radius: 3px;
  color: ${(p) => p.fontColor};
  line-height: normal;
  box-shadow: 3px 3px 6px ${(p) => darken(0.04, p.editorColor)}, -3px -3px 6px ${(p) => lighten(0.03, p.editorColor)};
  transition: box-shadow ease-in-out 0.4s, background ease-in-out 0.4s;
  &:disabled {
    cursor: auto;
    color: ${(p) => readableColor(p.editorColor, darken(0.1, p.editorColor), lighten(0.2, p.editorColor), false)};
  }
`;
